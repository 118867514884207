import { FC, HTMLAttributes, useCallback, useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';

import { CloseOutlined } from '@mui/icons-material';
import { DataContext } from 'contexts/DataContext';
import { DiscountIcon } from 'components/icons/DiscountIcon';
import classes from './NewsTicker.module.scss';
import { useAccountsUserProfile } from 'common/hooks/Accounts/useAccountsUserProfile';

export const NewsTicker: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const { openSubscriptionDialog$ } = useContext(DataContext);
  const [showNewsTicker, setShowNewsTicker] = useState(false);
  const { value: user } = useAccountsUserProfile();

  useEffect(() => {
    setShowNewsTicker(!!user && !user?.subscription);
  }, [user]);

  const onPricingPlanClicked = useCallback(() => {
    openSubscriptionDialog$.next(true);
  }, [openSubscriptionDialog$]);

  return showNewsTicker ? (
    <Stack direction='row' className={classes.root} justifyContent='space-between'>
      <Stack justifyContent='center' alignItems='center' className={classes.news}>
        <Stack direction='row' className={classes.textContainer} spacing={1.5}>
          <Stack className={classes.sale}>
            <Typography variant='subtitle2' className={classes.text}>
              SALE
            </Typography>
          </Stack>
          <DiscountIcon className={classes.icon} />
          <Typography>
            Get <span className={classes.highlight}>30%</span> OFF today! With promo code:{' '}
            <span className={classes.highlight}>WELCOME30</span>
            <span className={classes.linkInside} onClick={onPricingPlanClicked}>
              See pricing plan
            </span>
          </Typography>
        </Stack>
        <Stack direction='row' spacing={2}>
          <Typography className={classes.link} onClick={onPricingPlanClicked}>
            See pricing plan
          </Typography>
        </Stack>
      </Stack>
      <Stack className={classes.close}>
        <CloseOutlined onClick={() => setShowNewsTicker(false)} />
      </Stack>
    </Stack>
  ) : null;
};
