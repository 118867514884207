import {
  AddCircleOutline,
  AddOutlined,
  CallMadeOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutline,
  InfoOutlined,
  LockOutlined,
} from '@mui/icons-material';
import { Button, Checkbox, LinearProgress, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { CommonTable, CommonTableColumn, PaginationOptions } from 'components/table/CommonTable';
import { FC, HTMLAttributes, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { OrderBy, OrderByField, OrderByFieldNameMap } from 'common/types/Extension/InfluencerFilter';
import {
  formatPercentileRank,
  getPercentileColor,
  getPercentileColorHex,
  numberToAbbreviation,
  numberToScoreSecondFraction,
  redirectToExternalPage,
} from 'common/utils';

import { AscendingIcon } from 'components/icons/AscendingIcon';
import { CommonChip } from 'components/chip/CommonChip';
import { DataContext } from 'contexts/DataContext';
import { DescendingIcon } from 'components/icons/DescendingIcon';
import { LabelChip } from 'components/chip/LabelChip';
import { TweetsDialog } from 'components/Twitter/TweetsDialog';
import { TwitterUserInfo } from 'components/Twitter/TwitterUserInfo';
import { TwitterUserSearchResult } from 'common/types/Extension/TwitterUserSearchResult';
import { Uris } from 'Uris';
import classes from './TwitterUserList.module.scss';
import { colorPrimary40 } from 'common/params';
import { getLangFromIso } from 'common/languages';
import { useMessage } from 'components/message/useMessage';
import { useUserProfile } from 'common/hooks/useUserProfile';

interface TwitterUserListProps extends HTMLAttributes<HTMLElement> {
  showLimit?: boolean;
  clearSelected?: boolean;
  mobileMode?: boolean;
  dataPagination?: boolean;
  loading?: boolean;
  pageNum?: number;
  pageNumLimit?: number;
  defaultRowsPerPage?: number;
  rowsPerPageOptions?: number[];
  twitterUsers: TwitterUserSearchResult[];
  addedTwitterUsers?: TwitterUserSearchResult[];
  totalDataCnt?: number;
  orderBy?: OrderBy;
  orderByOptions?: OrderByField[];
  onAddTwitterUsersToList?: (twitterUsers: TwitterUserSearchResult[]) => void;
  onDeleteTwitterUser?: (twitterUser: TwitterUserSearchResult) => void;
  onTwitterUserViewClicked?: (twitterUser: TwitterUserSearchResult) => void;
  onPageChange?: (paginationOptions: PaginationOptions) => void;
  onSelectedClear?: () => void;
  onEditOrderBy?: (orderBy: OrderBy) => void;
}

const renderPercentage = (data?: number) => {
  if (typeof data !== 'number') return 'N/A';
  return `${Math.round(data * 10000) / 100} %`;
};

const maxSelectedNum = 1000;

export const TwitterUserList: FC<TwitterUserListProps> = ({
  showLimit,
  clearSelected,
  mobileMode,
  dataPagination,
  loading,
  pageNum,
  pageNumLimit,
  defaultRowsPerPage,
  rowsPerPageOptions,
  twitterUsers,
  addedTwitterUsers,
  totalDataCnt,
  orderBy,
  orderByOptions,
  onAddTwitterUsersToList,
  onDeleteTwitterUser,
  onTwitterUserViewClicked,
  onPageChange,
  onSelectedClear,
  onEditOrderBy,
  ...rest
}) => {
  const { openSubscriptionDialog$ } = useContext(DataContext);
  const { showMessage } = useMessage();
  const [selectedTwitterUsers, setSelectedTwitterUsers] = useState<TwitterUserSearchResult[]>([]);
  const [mentionTweets, setMentionTweets] = useState<
    { username: string; tweetIds: string[]; mentionedCount: number } | undefined
  >(undefined);
  const [exceedLimit, setExceedLimit] = useState<boolean>(false);

  const [showUsers, setShowUsers] = useState<TwitterUserSearchResult[]>([]);

  const { value: user } = useUserProfile();

  useEffect(() => {
    setExceedLimit(false);
  }, [selectedTwitterUsers]);

  const onPaginationChanged = useCallback(
    (paginationOptions: PaginationOptions) => {
      onPageChange?.(paginationOptions);
    },
    [onPageChange],
  );

  useEffect(() => {
    if (!clearSelected) return;
    setSelectedTwitterUsers([]);
    onSelectedClear?.();
  }, [clearSelected, onSelectedClear]);

  const isAllSelected = useMemo(() => {
    if (!showUsers.length) return false;
    return showUsers.every(
      (twitterUser) =>
        selectedTwitterUsers.findIndex((selectedTwitterUser) => selectedTwitterUser.id === twitterUser.id) > -1,
    );
  }, [showUsers, selectedTwitterUsers]);

  const isPartialSelected = useMemo(() => {
    return (
      !isAllSelected &&
      showUsers.some(
        (twitterUser) =>
          selectedTwitterUsers.findIndex((selectedTwitterUser) => selectedTwitterUser.id === twitterUser.id) > -1,
      )
    );
  }, [isAllSelected, showUsers, selectedTwitterUsers]);

  const columnDefs: CommonTableColumn<TwitterUserSearchResult>[] = [
    {
      id: 'id',
      label: '#',
      startAdornment: (
        <Checkbox
          checked={isAllSelected}
          indeterminate={isPartialSelected}
          disabled={!twitterUsers.length}
          onChange={(e) => {
            if (!e.target.checked) {
              // de-select all current show twitter users
              setSelectedTwitterUsers((old) => [
                ...old.filter(
                  (twitterUser) => showUsers.findIndex((showUser) => showUser.id === twitterUser.id) === -1,
                ),
              ]);
            } else {
              // select all current show twitter users
              setSelectedTwitterUsers((old) => {
                const newArr = [
                  ...old,
                  ...showUsers.filter((twitterUser) => old.findIndex((user) => user.id === twitterUser.id) === -1),
                ];
                if (newArr.length > maxSelectedNum) {
                  showMessage(`You can't select more than 1k influencers at once`, 'warning');
                  setExceedLimit(true);
                  return old;
                }
                return newArr;
              });
            }
          }}
        ></Checkbox>
      ),
      render: (value, idx, data) => {
        return (
          <Checkbox
            id={`check-box-select-influencer-${idx}`}
            checked={selectedTwitterUsers.findIndex((selectedTwitterUser) => selectedTwitterUser.id === value) > -1}
            onChange={(e) => {
              if (!data) return;
              if (e.target.checked) {
                setSelectedTwitterUsers((old) => {
                  const newArr = [...old, data];
                  if (newArr.length > maxSelectedNum) {
                    showMessage(`You can't select more than 1k influencers at once`, 'warning');
                    setExceedLimit(true);
                    return old;
                  }
                  return newArr;
                });
              } else {
                setSelectedTwitterUsers((old) => {
                  return [...old.filter((selectedTwitterUser) => selectedTwitterUser.id !== value)];
                });
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        );
      },
    },
    {
      id: 'display_name',
      label: 'Name',
      sortable: true,
      render: (_, __, data) => {
        if (!data) return <></>;
        return (
          <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
            <Stack spacing={0.5}>
              <Stack className={classes.profile}>
                <TwitterUserInfo user={data} />
              </Stack>
              {typeof data?.match_tweets_count === 'number' && data.tweet_ids?.length ? (
                <Stack
                  className={classes.tweets}
                  direction='row'
                  spacing={1}
                  alignItems='center'
                  onClick={(e) => {
                    setMentionTweets({
                      username: data.username || '',
                      tweetIds: data.tweet_ids || [],
                      mentionedCount: data.match_tweets_count || 0,
                    });
                    e.stopPropagation();
                  }}
                >
                  <CallMadeOutlined fontSize='small' className={classes.link} />
                  <Typography className={classes.info} variant='label1'>
                    {data?.match_tweets_count} Tweets mentioned
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
            <Stack direction='row' spacing={0.5} alignItems='center'>
              {(addedTwitterUsers || []).findIndex((addedTwitterUser) => addedTwitterUser.id === data.id) > -1 ? (
                <CheckOutlined className={classes.addedIcon} fontSize='small' />
              ) : (
                <AddCircleOutline
                  className={classes.actionBtn}
                  fontSize='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!user) {
                      redirectToExternalPage(`${Uris.External.Login}?entry_point=console`);
                      return;
                    }
                    onAddTwitterUsersToList?.([data]);
                  }}
                />
              )}
              {onDeleteTwitterUser ? (
                <DeleteOutline
                  className={classes.actionBtn}
                  fontSize='small'
                  onClick={(e) => {
                    onDeleteTwitterUser?.(data);
                    e.stopPropagation();
                  }}
                />
              ) : null}
            </Stack>
          </Stack>
        );
      },
    },
    {
      id: 'influence_score',
      label: 'Influence Score',
      tooltip:
        'Influence Score on X.com uses the PageRank algorithm and retweet data to measure impact. High-score retweets boost your score, reflecting the ripple effect of influence.',
      sortable: true,
      render: (value, _, data) => {
        if (typeof data?.influence_percentile !== 'number')
          return (
            <Typography variant='body1' className={classes.text}>
              N/A
            </Typography>
          );
        const color = getPercentileColor(data.influence_percentile);
        const colorHex = getPercentileColorHex(data.influence_percentile);
        return (
          <Tooltip arrow placement='top' title={`Percentile ${formatPercentileRank(data.influence_percentile)}`}>
            <Stack>
              <Typography variant='body1' className={classes.text} color={colorHex}>
                {typeof value === 'number' ? numberToAbbreviation(value) : value}
              </Typography>
              <LinearProgress
                variant='determinate'
                color={color}
                value={(data.influence_percentile || 0) * 100}
                sx={{
                  borderRadius: '2px',
                  width: '64px',
                }}
              />
            </Stack>
          </Tooltip>
        );
      },
    },
    {
      id: 'web3_score',
      label: 'Web3 Score',
      tooltip: "A score between 0 and 100 indicates how closely the tweet's content is related to Web3.",
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.text}>
            {typeof value === 'number' ? numberToScoreSecondFraction(value) : 'N/A'}
          </Typography>
        );
      },
    },
    {
      id: 'follower_count',
      label: 'Followers',
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.text}>
            {typeof value === 'number' ? numberToAbbreviation(value) : 'N/A'}
          </Typography>
        );
      },
    },
    {
      id: 'etr',
      label: 'ETR',
      tooltip: 'Average engagements(retweet+reply+like) / total followers',
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.text}>
            {renderPercentage(value)}
          </Typography>
        );
      },
    },
    {
      id: 'vtr',
      label: 'VTR',
      tooltip: 'Average tweet impressions / total followers',
      sortable: true,
      render: (value) => {
        if (typeof value !== 'number') return <Typography>N/A</Typography>;
        return (
          <Typography variant='body1' className={classes.text}>
            {renderPercentage(value)}
          </Typography>
        );
      },
    },
    {
      id: 'campaign_term_ratio',
      label: 'Campaign Term Ratio',
      tooltip:
        'Campaign tweets (tweets related to campaign keywords like airdrop, giveaway or RTs) count / original tweet counts.',
      sortable: true,
      render: (value) => {
        if (typeof value !== 'number') return <Typography>N/A</Typography>;
        return (
          <Typography variant='body1' className={classes.text}>
            {renderPercentage(value)}
          </Typography>
        );
      },
    },
    {
      id: 'original_tweets_ratio',
      label: 'Original Tweets Ratio',
      tooltip: 'original tweet counts / (original + retweet counts)',
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.text}>
            {renderPercentage(value)}
          </Typography>
        );
      },
    },
    {
      id: 'avg_tweet_impr',
      label: 'Avg. Tweets Impression',
      tooltip: 'Average impressions in the past 90 days.',
      sortable: true,
      render: (value) => {
        return (
          <Typography variant='body1' className={classes.text}>
            {typeof value === 'number' ? numberToAbbreviation(value) : 'N/A'}
          </Typography>
        );
      },
    },
    {
      id: 'languages',
      label: 'Language',
      render: (value: string[] | undefined) => {
        if (!value || (value?.length || 0) < 2) {
          return (
            <Stack direction='row' spacing={1} className={classes.chips}>
              {(value || []).map((language, idx) => (
                <LabelChip key={idx}>{getLangFromIso(language)}</LabelChip>
              ))}
            </Stack>
          );
        }
        return (
          <Stack direction='row' spacing={1} className={classes.chips}>
            <LabelChip tooltip={value.map((v) => getLangFromIso(v)).join(', ')}>+{value.length} more</LabelChip>
          </Stack>
        );
      },
    },
    {
      id: 'categories',
      label: 'Category',
      render: (value: string[] | undefined, _, data) => {
        if (!value || (value?.length || 0) < 3) {
          return (
            <Stack direction='row' spacing={1} className={classes.chips}>
              {(value || []).map((category, idx) => (
                <LabelChip key={idx}>{category}</LabelChip>
              ))}
            </Stack>
          );
        }
        const moreValue = value.slice(1);
        return (
          <Stack direction='row' spacing={1} className={classes.chips}>
            <LabelChip>{getLangFromIso(value[0])}</LabelChip>
            <LabelChip tooltip={moreValue.join(', ')}>+{moreValue.length} more</LabelChip>
          </Stack>
        );
      },
    },
    {
      id: 'location',
      label: 'Location',
      render: (value) => {
        if (!value) return null;
        return (
          <Stack direction='row' spacing={1} className={classes.chips}>
            <LabelChip>{value}</LabelChip>
          </Stack>
        );
      },
    },
  ];

  const tableHeader = useMemo(() => {
    return (
      <Stack
        direction='row'
        className={classes.tableHeader}
        alignItems='center'
        justifyContent='space-between'
        spacing={1}
      >
        <Stack spacing={1}>
          <Stack
            direction={mobileMode ? 'column' : 'row'}
            alignItems={mobileMode ? 'flex-start' : 'center'}
            spacing={mobileMode ? 1 : 3}
          >
            <Typography>{selectedTwitterUsers.length} influencers selected</Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Button
                variant='outlined'
                startIcon={<CloseOutlined />}
                onClick={() => {
                  if (!user) {
                    redirectToExternalPage(`${Uris.External.Login}?entry_point=console`);
                    return;
                  }
                  setSelectedTwitterUsers([]);
                }}
              >
                Deselect All
              </Button>
              <Button
                id='btn-table-add-influencer-to-list'
                variant='contained'
                startIcon={<AddOutlined />}
                disabled={!selectedTwitterUsers.length}
                onClick={() => {
                  if (!user) {
                    redirectToExternalPage(`${Uris.External.Login}?entry_point=console`);
                    return;
                  }
                  onAddTwitterUsersToList?.(selectedTwitterUsers);
                }}
              >
                Add to List
              </Button>
            </Stack>
          </Stack>

          {exceedLimit ? (
            <Stack direction='row' spacing={1} alignItems='center'>
              <InfoOutlined fontSize='small' color='error' />
              <Typography variant='label1' color='error'>
                You can't select more than 1k influencers at once
              </Typography>
            </Stack>
          ) : null}
        </Stack>
        {orderBy && onEditOrderBy ? (
          <Stack direction='row' alignItems='center' spacing={1.5}>
            <Typography variant='label1'>Sort by</Typography>
            <Select
              value={orderBy?.field}
              sx={{
                color: colorPrimary40,
                borderRadius: '48px',
                width: '160px',
                height: '40px',
              }}
              onChange={(e) => onEditOrderBy?.({ field: e.target.value, order: orderBy.order || 'asc' })}
            >
              {(orderByOptions || []).map((option) => (
                <MenuItem key={option} value={option}>
                  <Typography variant='label1'>{OrderByFieldNameMap.get(option)}</Typography>
                </MenuItem>
              ))}
            </Select>
            {orderBy.order === 'asc' ? (
              <AscendingIcon
                className={classes.sortOrder}
                onClick={() => onEditOrderBy?.({ field: orderBy.field, order: 'desc' })}
              />
            ) : (
              <DescendingIcon
                className={classes.sortOrder}
                onClick={() => onEditOrderBy?.({ field: orderBy.field, order: 'asc' })}
              />
            )}
          </Stack>
        ) : null}
      </Stack>
    );
  }, [
    mobileMode,
    exceedLimit,
    user,
    selectedTwitterUsers,
    orderBy,
    orderByOptions,
    onAddTwitterUsersToList,
    onEditOrderBy,
  ]);

  const blurData = useMemo(() => {
    if (!showLimit) return null;
    return (
      <Stack className={classes.blur} alignItems='center' justifyContent='center' spacing={2}>
        <CommonChip>
          <LockOutlined fontSize='large' />
        </CommonChip>
        <Typography className={classes.text} variant='h6'>
          Upgrade to unlock access to all {totalDataCnt} influencers
        </Typography>
        <Button variant='contained' onClick={() => openSubscriptionDialog$.next(true)}>
          Upgrade
        </Button>
      </Stack>
    );
  }, [showLimit, totalDataCnt, openSubscriptionDialog$]);

  return (
    <>
      <CommonTable
        {...rest}
        defaultRowsPerPage={defaultRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        dataPagination={dataPagination}
        loading={loading}
        pageNum={pageNum}
        data={twitterUsers}
        totalDataCnt={totalDataCnt}
        columns={columnDefs}
        classes={classes}
        header={tableHeader}
        dataFooter={blurData}
        onPageChange={onPaginationChanged}
        onShowDataChange={(data) => setShowUsers(data)}
        onRowClicked={(data) => {
          onTwitterUserViewClicked?.(data);
        }}
        labelDisplayedRows={
          showLimit
            ? ({ from, to, count }) => `${from}-${twitterUsers.length} of ${count !== -1 ? count : `more than ${to}`}`
            : undefined
        }
        pageNumLimit={pageNumLimit}
      />
      {mentionTweets?.tweetIds.length ? (
        <TweetsDialog
          open
          username={mentionTweets.username}
          tweetIds={mentionTweets.tweetIds}
          mentionedCount={mentionTweets.mentionedCount}
          onCancel={() => setMentionTweets(undefined)}
        />
      ) : null}
    </>
  );
};
