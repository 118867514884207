import { SvgIcon, SvgIconProps } from '@mui/material';

export const DiscountIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox='0 0 25 25'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M10.3 3.5a3 3 0 0 1 4.4 0l.7.8a1 1 0 0 0 .8.3h1a3 3 0 0 1 3.2 3.1v1a1 1 0 0 0 .3.9l.8.7a3 3 0 0 1 0 4.4l-.8.7a1 1 0 0 0-.3.8v1a3 3 0 0 1-3.1 3.2h-1a1 1 0 0 0-.9.3l-.7.8a3 3 0 0 1-4.4 0l-.7-.8a1 1 0 0 0-.8-.3h-1a3 3 0 0 1-3.2-3.1v-1a1 1 0 0 0-.3-.9l-.8-.7a3 3 0 0 1 0-4.4l.8-.7a1 1 0 0 0 .3-.8v-1a3 3 0 0 1 3.1-3.2h1a1 1 0 0 0 .9-.3l.7-.8Zm6 5.3a1 1 0 0 1 0 1.4l-6 6a1 1 0 0 1-1.5-1.4l6-6a1 1 0 0 1 1.4 0ZM10 8.5A1.5 1.5 0 0 0 8.5 10a1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5A1.5 1.5 0 0 0 10 8.5Zm5 5a1.5 1.5 0 0 0-1.5 1.5 1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5 1.5 1.5 0 0 0-1.5-1.5Z'
      clipRule='evenodd'
    />
  </SvgIcon>
);
