import { Collapse, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { Web3ScoreFilter, getValueFilterString } from 'common/types/Extension/InfluencerFilter';

import { InfoOutlined } from '@mui/icons-material';
import { MinMaxValueInput } from 'components/input/MinMaxValueInput';
import classes from './Web3ScoreFilter.module.scss';
import { numberToScoreSecondFraction } from 'common/utils';

interface Web3ScoreFilterCollapseProps {
  data?: Web3ScoreFilter;
  onChange?: (data?: Web3ScoreFilter) => void;
}

export const Web3ScoreFilterCollapse: FC<Web3ScoreFilterCollapseProps> = ({ data, onChange }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack className={classes.root}>
      <Stack
        direction='row'
        className={classes.title}
        justifyContent='space-between'
        onClick={() => setOpen((old) => !old)}
      >
        <Stack direction='row' alignItems='center' spacing={3}>
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <Typography>Web3 Score</Typography>
            <Tooltip
              title="A score between 0 and 100 indicates how closely the tweet's content is related to Web3."
              placement='top'
            >
              <InfoOutlined fontSize='small' />
            </Tooltip>
          </Stack>
          <Typography className={classes.value} variant='label1'>
            {getValueFilterString(data, numberToScoreSecondFraction)}
          </Typography>
        </Stack>
        {open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
      </Stack>
      <Collapse in={open} timeout='auto'>
        <Stack spacing={3}>
          <Divider className={classes.divider} />
          <Stack spacing={3} className={classes.input}>
            <MinMaxValueInput
              roundLimit
              min={typeof data?.min === 'number' ? data.min * 100 : undefined}
              max={typeof data?.max === 'number' ? data.max * 100 : undefined}
              minLimit={0}
              maxLimit={100}
              onChange={(min, max) => {
                if (min === undefined && max === undefined) {
                  onChange?.(undefined);
                  return;
                }
                onChange?.({
                  ...data,
                  min: typeof min === 'number' ? min / 100 : min,
                  max: typeof max === 'number' ? max / 100 : max,
                  top: undefined,
                });
              }}
            />
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
