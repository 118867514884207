import { AppBar, Button, InputAdornment, OutlinedInput, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { FC, useContext, useMemo } from 'react';

import { DataContext } from 'contexts/DataContext';
import { Logo } from 'components/layout/Logo';
import { RoundStarIcon } from 'components/icons/RoundStarIcon';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Uris } from 'Uris';
import { User } from 'common/types/User';
import { UserAvatar } from 'components/User/UserAvatar';
import classes from './Header.module.scss';
import { redirectToExternalPage } from 'common/utils';
import { useAccountsUserProfile } from 'common/hooks/Accounts/useAccountsUserProfile';

interface HeaderProps {
  user?: User;
}

export const Header: FC<HeaderProps> = ({ user }) => {
  const { value: accountsUser } = useAccountsUserProfile();
  const { openSubscriptionDialog$, openCreditDialog$ } = useContext(DataContext);
  const avatarBtn = useMemo(() => {
    if (user) return <UserAvatar user={user} />;
    return (
      <Button variant='contained' onClick={() => redirectToExternalPage(`${Uris.External.Login}?entry_point=console`)}>
        Getting Started
      </Button>
    );
  }, [user]);

  /** Render */
  return (
    <AppBar position='fixed' sx={{ backgroundColor: 'white', boxShadow: 'none', position: 'absolute' }}>
      <Toolbar className={classes.root}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ width: '100%' }}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Stack direction='row' className={classes.center}>
              <OutlinedInput
                size='medium'
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchOutlinedIcon />
                  </InputAdornment>
                }
                placeholder='Search accounts, wallet address, assets...'
                className={classes.search}
              />
            </Stack>
            {accountsUser ? (
              <>
                <Tooltip arrow title='Learn how to get more credit'>
                  <Stack
                    direction='row'
                    alignItems='center'
                    className={classes.credits}
                    spacing={1}
                    onClick={() => openCreditDialog$.next(true)}
                  >
                    <RoundStarIcon fontSize='small' />
                    <Typography variant='label1'>{accountsUser.credits.balance} credits left</Typography>
                  </Stack>
                </Tooltip>
                <Stack direction='row' alignItems='center' className={classes.subscription}>
                  <Typography variant='label1' className={classes.plan}>
                    {accountsUser.subscription
                      ? accountsUser.subscription.plan_name
                      : accountsUser.trial
                      ? accountsUser.trial.plan_name
                      : '-'}
                  </Typography>
                  <Typography
                    variant='label1'
                    className={classes.upgrade}
                    onClick={() => openSubscriptionDialog$.next(true)}
                  >
                    Upgrade
                  </Typography>
                </Stack>
              </>
            ) : null}
            {avatarBtn}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
