import { FC, HTMLAttributes, useContext, useEffect } from 'react';

import ClientAPI from 'common/ClientAPI';
import { DataContext } from 'contexts/DataContext';
import { Spinner } from 'components/common/Spinner';
import { useAsync } from 'react-use';
import { useMessage } from 'components/message/useMessage';

export const ConfigProvider: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  const { activeWalletCount$, walletAssets$, defiProjects$ } = useContext(DataContext);
  const { showMessage } = useMessage();

  const activeWalletCountRes = useAsync(async () => {
    return (await ClientAPI.getActiveWalletCount()).data;
  }, []);

  const walletAssetsRes = useAsync(async () => {
    return (await ClientAPI.getWalletAssets()).data;
  }, []);

  const defiProjectsRes = useAsync(async () => {
    return (await ClientAPI.getDefiProjects()).data;
  }, []);

  useEffect(() => {
    if (activeWalletCountRes.loading) return;
    if (activeWalletCountRes.error) {
      showMessage('Get active wallet count failed', 'error');
      return;
    }
    activeWalletCount$.next(activeWalletCountRes.value || 0);
  }, [showMessage, activeWalletCountRes, activeWalletCount$]);

  useEffect(() => {
    if (walletAssetsRes.loading) return;
    if (walletAssetsRes.error) {
      showMessage('Get wallet assets failed', 'error');
      return;
    }
    walletAssets$.next(
      (walletAssetsRes.value || []).concat([
        {
          id: 'matic-network',
          chain: 'polygon',
          symbol: 'matic',
          price: 0.983267,
          contract_type: 'token',
          contract_addr: '0xpolygonfffffffffffffffffffffffffffffffff',
        },
      ]),
    );
  }, [showMessage, walletAssetsRes, walletAssets$]);

  useEffect(() => {
    if (defiProjectsRes.loading) return;
    if (defiProjectsRes.error) {
      showMessage('Get defi projects failed', 'error');
      return;
    }
    defiProjects$.next(defiProjectsRes.value || []);
  }, [showMessage, defiProjectsRes, defiProjects$]);

  if (walletAssetsRes.loading) return <Spinner />;

  return <>{children}</>;
};
