import { Box, Stack } from '@mui/material';
import { FC, HTMLAttributes, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BlockDialog } from './BlockDialog';
import { CreditDialog } from 'components/Auth/CreditDialog';
import { DataContext } from 'contexts/DataContext';
import { Header } from 'components/layout/Header';
import { NewsTicker } from 'components/layout/NewsTicker';
import { Outlet } from 'react-router';
import { SideBar } from 'components/layout/SideBar';
import { Spinner } from 'components/common/Spinner';
import { SubscriptionDialog } from 'components/Auth/SubscriptionDialog';
import { TutorialProvider } from 'components/Tutorial/TutorialProvider';
import { Uris } from 'Uris';
import { UserSideBar } from './UserSideBar';
import classNames from 'classnames';
import classes from './LayoutProvider.module.scss';
import { setCookie } from 'common/utils';
import { useExtensionFeatureLimits } from 'common/hooks/Extension/useExtensionFeatureLimits';
import { useFeatureLimits } from 'common/hooks/useFeatureLimits';
import { useObservable } from 'react-use';
import { useSubscriptionPlans } from 'common/hooks/Accounts/useSubscriptionPlans';
import { useTrackingInit } from 'common/hooks/useTrackingInit';
import { useUserProfile } from 'common/hooks/useUserProfile';

const hideSideBarPathList = [
  Uris.Pages.User.Survey,
  Uris.Pages.User.Expired,
  Uris.Pages.User.Info,
  Uris.Pages.User.Integration.Index,
  Uris.Pages.Public.Collection,
];
const hideHeaderPathList = [Uris.Pages.User.Survey, Uris.Pages.User.Expired];

export const LayoutProvider: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const { isBlock$, openSubscriptionDialog$, openCreditDialog$ } = useContext(DataContext);
  const isBlock = useObservable(isBlock$);
  const openSubscriptionDialog = useObservable(openSubscriptionDialog$);
  const openCreditDialog = useObservable(openCreditDialog$);
  const navigate = useNavigate();
  // for future usage
  const { pathname, search } = useLocation();
  const [open] = useState(true);
  const { value: userProfile, loading } = useUserProfile();

  useSubscriptionPlans();
  useFeatureLimits();
  useExtensionFeatureLimits();
  useTrackingInit();

  useEffect(() => {
    const parameters = new URLSearchParams(search);
    const activity_code = parameters.get('activity_code');
    if (activity_code) setCookie('activity_code', activity_code);
  }, [search]);

  const hideHeader = useMemo(() => {
    return (
      pathname !== '/' &&
      hideHeaderPathList.findIndex((path) => {
        const semiColon = path.indexOf(':');
        const pathStr = semiColon > -1 ? path.substring(0, semiColon) : path;
        return pathStr.startsWith(pathname.substring(0, pathStr.length));
      }) > -1
    );
  }, [pathname]);

  const tutorialEnabled = useMemo(() => {
    if (!userProfile) return false;
    if (
      pathname !== '/' &&
      hideSideBarPathList.findIndex((path) => {
        const semiColon = path.indexOf(':');
        const pathStr = semiColon > -1 ? path.substring(0, semiColon) : path;
        return pathStr.startsWith(pathname.substring(0, pathStr.length));
      }) > -1
    )
      return false;
    return true;
  }, [pathname, userProfile]);

  const sideBarOpen = useMemo(() => {
    if (!userProfile) return false;
    if (
      pathname !== '/' &&
      hideSideBarPathList.findIndex((path) => {
        const semiColon = path.indexOf(':');
        const pathStr = semiColon > -1 ? path.substring(0, semiColon) : path;
        return pathStr.startsWith(pathname.substring(0, pathStr.length));
      }) > -1
    )
      return false;
    return open;
  }, [open, pathname, userProfile]);

  const isUserPage = useMemo(() => {
    return pathname.startsWith(Uris.Pages.User.Index);
  }, [pathname]);

  useEffect(() => {
    if (!userProfile) return;
    if (!userProfile.survey) navigate(Uris.Pages.User.Survey);
  }, [userProfile, navigate]);

  /** Render */
  return (
    <Stack>
      <NewsTicker />
      <Box className={classes.root}>
        {!hideHeader && <Header user={userProfile} />}
        <SideBar open={sideBarOpen} />
        {isUserPage ? <UserSideBar open /> : null}

        <div
          className={classNames(
            classes.main,
            sideBarOpen && classes.sideBar,
            isUserPage && classes.userSideBar,
            hideHeader && classes.hideHeader,
          )}
        >
          {loading ? <Spinner /> : <Outlet />}
        </div>
        {tutorialEnabled ? <TutorialProvider /> : null}
        {isBlock ? <BlockDialog open onDialogClose={() => isBlock$.next(false)} /> : null}
        {openSubscriptionDialog ? (
          <SubscriptionDialog open onBtnCancelClicked={() => openSubscriptionDialog$.next(false)} />
        ) : null}
        {openCreditDialog ? (
          <CreditDialog
            open
            onBtnCancelClicked={() => openCreditDialog$.next(false)}
            onBtnUpgradeClicked={() => {
              openCreditDialog$.next(false);
              openSubscriptionDialog$.next(true);
            }}
          />
        ) : null}
      </Box>
    </Stack>
  );
};
