import { colorError40, colorTertiary40 } from './params';
import { datetimeFormatter, numberFormatter } from 'common/formatters';

import { AddressMetric } from './types/AddressMetrics';
import { Config } from 'config';
import baseX from 'base-x';

const BASE_16_CHARSET = '0123456789abcdef';
const BASE_36_CHARSET = '0123456789abcdefghijklmnopqrstuvwxyz';
const base16 = baseX(BASE_16_CHARSET);
const base36 = baseX(BASE_36_CHARSET);

export const validateEmail = (email: string) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email);
};

export const validatePassword = (password: string) => {
  // minimum length is 6
  // at least 1 character
  // at least 1 digit
  return /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/.test(password);
};

export const validateAddress = (address: string) => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};

export const validateUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const validateTwitterUserUrl = (url: string) => {
  return /^https:\/\/twitter\.com\/([a-zA-Z0-9_]+)$/.test(url) || /^https:\/\/x\.com\/([a-zA-Z0-9_]+)$/.test(url);
};

export const dedupArray = (a: string[]) => Array.from(new Set(a));

export const replaceArrayIdxWithValue = <T>(arr: T[], idx: number, value: T) => {
  if (idx < 0 || idx >= arr.length) throw Error(`index ${idx} out of array range [0, ${arr.length - 1}]`);
  return [...arr.slice(0, idx), value, ...arr.slice(idx + 1)];
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return (parts.pop() || '').split(';').shift();
};

export const setCookie = (name: string, value: string, days: number = 7) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const downloadAsCsv = (data: any[], filename = 'data.csv') => {
  const columnHeaders = Object.keys(data[0]);
  const csvData = [
    columnHeaders,
    ...data.map((d) =>
      columnHeaders.map((header) => {
        switch (typeof d[header]) {
          // for object type -> need to do some operation
          case 'number':
            if (header.endsWith('_at')) {
              return datetimeFormatter.format(d[header] * 1000);
            }
            return d[header];
          case 'string':
            return `"${d[header].replaceAll('"', "'")}"`;
          case 'object':
            // serialize and escape double quote, then enclose string with ""
            return `"${JSON.stringify(d[header]).replaceAll('"', '""')}"`;
          default:
            return d[header];
        }
      }),
    ),
  ]
    .map((row) => row.join(','))
    .join('\n');
  const blob = new Blob([csvData], { type: 'text/csv' });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

export const fileSizeToString = (bytes: number, decimals = 2, base = 1000) => {
  if (bytes === 0) return '0 Bytes';

  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(base));

  return parseFloat((bytes / Math.pow(base, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const hexToBase36 = (hex: string) => {
  return base36.encode(base16.decode(hex));
};

export const getGAClientId = () => {
  const gaCookie = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
  const rawClientId = gaCookie ? decodeURIComponent(gaCookie[1]) : null;
  let clientId = null;
  if (rawClientId) clientId = rawClientId.match(/(\d+\.\d+)$/);
  return clientId ? 'c_' + clientId[1] : null;
};

export const redirectToExternalPage = (externalUrl: string, redirectUrl?: string) => {
  document.cookie = `redirect_url=${redirectUrl || window.location.href};domain=${Config.CookieDomain};path=/`;
  window.location.replace(externalUrl);
};

export const numberToAbbreviation = (input: number, decimals = 2, base = 1000) => {
  const value = Math.abs(input);
  const sign = input >= 0 ? 1 : -1;
  if (value === 0) return '0';

  let dm = decimals < 0 ? 0 : decimals;
  const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

  const i = Math.floor(Math.log(value) / Math.log(base));

  const showValue = value / Math.pow(base, i);
  // based on pm's decision
  // only show one decimal for value that has three digits before floating point
  if (base === 1000 && showValue > 100) {
    dm = decimals - 1;
  }

  return ((Math.floor(showValue * Math.pow(10, dm)) / Math.pow(10, dm)) * sign).toString() + sizes[i];
};

export const numberToPercentage = (data?: number) => {
  if (typeof data !== 'number') return '';
  return `${Math.round(data * 10000) / 100} %`;
};

export const numberToSecondFraction = (data?: number) => {
  if (typeof data !== 'number') return '';
  return numberFormatter.format(Math.round(data * 100) / 100);
};

export const numberToScoreSecondFraction = (data?: number) => {
  if (typeof data !== 'number') return '';
  return numberFormatter.format(Math.round(data * 10000) / 100);
};

export const minutesToString = (time: number) => {
  if (time < 60) return `${time} mins`;
  return `${Math.round((time / 60) * 100) / 100} hours`;
};

export const getAggregatedPieChartData = (metrics?: AddressMetric[], minShowThreshold: number = 0.01) => {
  if (!metrics?.length) return { labels: [], data: [] };
  const sum = metrics.map((m) => m.value).reduce((prev, curr) => prev + curr, 0);

  const labels: string[] = [];
  const data: number[] = [];
  let otherValue = 0;
  metrics.forEach((m) => {
    if (m.value / sum >= minShowThreshold) {
      labels.push(m.label);
      data.push(m.value);
      return;
    }
    otherValue += m.value;
  });
  if (otherValue > 0) {
    labels.push('Other');
    data.push(otherValue);
  }
  return { labels, data };
};

export const getPercentileColor = (data?: number) => {
  return typeof data === 'number' ? (data >= 0.7 ? 'teritary' : data <= 0.3 ? 'error' : 'warning') : undefined;
};

export const getPercentileColorHex = (data?: number) => {
  return typeof data === 'number'
    ? data >= 0.7
      ? colorTertiary40
      : data <= 0.3
      ? colorError40
      : '#ed6c02'
    : undefined;
};

export const formatPercentileRank = (percentRank: number) => {
  const value = Math.round(percentRank * 100);
  let prefix = 'th';
  switch (value % 10) {
    case 1:
      if (value !== 11) prefix = 'st';
      break;
    case 2:
      if (value !== 12) prefix = 'nd';
      break;
    case 3:
      if (value !== 13) prefix = 'rd';
      break;
  }

  return numberFormatter.format(value) + prefix;
};

export const getCurrencySymbol = (currency: string, locale: string = 'en-US') => {
  try {
    return (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  } catch {
    return currency;
  }
};
