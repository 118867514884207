import { Button, Drawer, Stack, Typography } from '@mui/material';
import { HomeOutlined, ManageAccountsOutlined } from '@mui/icons-material';
import { appBarHeight, colorSurface1, sideBarWidth } from 'common/params';
import { useLocation, useNavigate } from 'react-router-dom';

import { AdsAudienceIcon } from 'components/icons/AdsAudienceIcon';
import { FC } from 'react';
import { InfluencerMatcherIcon } from 'components/icons/InfluencerMatcherIcon';
import { Uris } from 'Uris';
import classNames from 'classnames';
import classes from './SideBar.module.scss';

interface MenuLink {
  icon: React.ReactNode; // icon for the menu link
  label: string; // display label for the menu link
  uri: string; // uri for the menu link
  id: string;
}

const menuLinks: MenuLink[] = [
  {
    icon: <HomeOutlined />,
    label: 'Home',
    uri: Uris.Pages.Root,
    id: 'side-bar-home',
  },
  {
    icon: <ManageAccountsOutlined />,
    label: 'Wallet Selector',
    uri: Uris.Pages.WalletSelector.Index,
    id: 'side-bar-wallet-selector',
  },
  {
    icon: <AdsAudienceIcon />,
    label: 'Ads Audience',
    uri: Uris.Pages.AdsAudience.Index,
    id: 'side-bar-ads-audience',
  },
  {
    icon: <InfluencerMatcherIcon />,
    label: 'Influencer Matcher',
    uri: Uris.Pages.InfluencerMatcher.Index,
    id: 'side-bar-influencer-matcher',
  },
];

interface SideBarProps {
  open: boolean;
}

export const SideBar: FC<SideBarProps> = ({ open }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /** Render */
  return (
    <Drawer
      className={classes.root}
      sx={{
        '& .MuiDrawer-paper': {
          width: sideBarWidth,
          boxSizing: 'border-box',
          backgroundColor: colorSurface1,
          top: appBarHeight,
          position: 'absolute',
        },
      }}
      variant='persistent'
      anchor='left'
      open={open}
    >
      <Stack direction='column' className={classes.container} spacing={4} alignItems='center'>
        {menuLinks.map((menuLink) => {
          const selected = menuLink.uri.length > 1 ? pathname.startsWith(menuLink.uri) : pathname === menuLink.uri;
          return (
            <Button
              id={menuLink.id}
              key={menuLink.label}
              className={classNames(classes.link, selected && classes.selected)}
              onClick={() => navigate(menuLink.uri)}
            >
              <Stack alignItems='center' spacing={1}>
                {menuLink.icon}

                <Typography variant='label2' className={classes.label}>
                  {menuLink.label}
                </Typography>
              </Stack>
            </Button>
          );
        })}
      </Stack>
    </Drawer>
  );
};
