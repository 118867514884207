import { SearchBarMenu } from 'pages/InfluencerMatcher/Search/SearchBar';
import { numberToPercentage } from 'common/utils';

export enum PercentileLevel {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
}
export const PercentileValueMap = new Map<PercentileLevel, number>([
  [PercentileLevel.TOP, 70],
  [PercentileLevel.MIDDLE, 30],
  [PercentileLevel.BOTTOM, 0],
]);
export const PercentileNameMap = new Map<PercentileLevel, string>([
  [PercentileLevel.TOP, 'Top'],
  [PercentileLevel.MIDDLE, 'Middle'],
  [PercentileLevel.BOTTOM, 'Bottom'],
]);

interface ValueFilter {
  min?: number;
  max?: number;
  top?: number;
  percentiles?: PercentileLevel[];
}

export interface KeywordSearch {
  type: string;
  keyword: string;
  times?: number;
  days?: number;
}

export interface FollowersFilter extends ValueFilter {}

export interface EngagementRateFilter extends ValueFilter {}

export interface ViewThroughRateFilter extends ValueFilter {}

export interface CampaignTermRatioFilter extends ValueFilter {}

export interface OriginalTweetsRatioFilter extends ValueFilter {}

export interface AverageTweetImpressionFilter extends ValueFilter {}

export interface InfluencerScoreFilter extends ValueFilter {}

export interface Web3ScoreFilter extends ValueFilter {}

export interface OrderBy {
  field: string;
  order: 'asc' | 'desc';
}

export enum OrderByField {
  INFLUENCE_SCORE = 'influence_score',
  FOLLOWERS = 'followers',
  ETR = 'etr',
  VTR = 'vtr',
  CAMPAIGN_TERM_RATIO = 'campaign_term_ratio',
  ORIGINAL_TWEETS_RATIO = 'original_tweets_ratio',
  AVERAGE_TWEET_IMPRESSION = 'avg_tweet_impr',
  TWEET_MENTIONED = 'tweet_mentioned',
}

export const OrderByFieldNameMap = new Map<OrderByField, string>([
  [OrderByField.INFLUENCE_SCORE, 'Influence Score'],
  [OrderByField.FOLLOWERS, 'Followers'],
  [OrderByField.ETR, 'ETR'],
  [OrderByField.VTR, 'VTR'],
  [OrderByField.CAMPAIGN_TERM_RATIO, 'Campaign Term Ratio'],
  [OrderByField.ORIGINAL_TWEETS_RATIO, 'Original Tweets Ratio'],
  [OrderByField.AVERAGE_TWEET_IMPRESSION, 'Avg. Tweets Impr.'],
  [OrderByField.TWEET_MENTIONED, 'Tweet mentioned'],
]);

export interface InfluencerSearchParams {
  keyword_search?: KeywordSearch;
  followers?: FollowersFilter;
  engagement_rate?: EngagementRateFilter;
  view_through_rate?: ViewThroughRateFilter;
  campaign_term_ratio?: CampaignTermRatioFilter;
  original_tweets_ratio?: OriginalTweetsRatioFilter;
  average_tweet_impression?: AverageTweetImpressionFilter;
  influence_score?: InfluencerScoreFilter;
  web3_score?: InfluencerScoreFilter;
  categories?: string[];
  user_ids?: string[];
  verified_only?: boolean;
  languages?: string[];
  locations?: string[];
  is_project?: boolean;
  order_by?: OrderBy;
}

export const DefaultInfluencerSearchParams: InfluencerSearchParams = {
  keyword_search: {
    type: SearchBarMenu.USER_INFO,
    keyword: '',
    times: 1,
    days: 90,
  },
  is_project: false,
  order_by: {
    field: OrderByField.INFLUENCE_SCORE,
    order: 'desc',
  },
};

export const getValueFilterString = (filter?: ValueFilter, transform?: (input: number) => string) => {
  if (typeof filter?.percentiles === 'object' && filter.percentiles.length) {
    return `PR: ${filter.percentiles.map((percentile) => PercentileNameMap.get(percentile)).join(', ')}`;
  }
  if (typeof filter?.top === 'number') {
    return `Top ${numberToPercentage(filter.top)}`;
  }
  if (typeof filter?.min !== 'number') {
    return typeof filter?.max === 'number' ? `≤ ${transform ? transform(filter.max) : filter.max}` : '';
  }
  if (typeof filter?.max !== 'number') {
    return typeof filter?.min === 'number' ? `≥ ${transform ? transform(filter.min) : filter.min}` : '';
  }
  if (filter.min === filter.max) return `${transform ? transform(filter.min) : filter.max}`;
  return `${transform ? transform(filter.min) : filter.min} - ${transform ? transform(filter.max) : filter.max}`;
};
