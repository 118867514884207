import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { DefaultUserSurveyData, UserSurvey } from 'common/types/UserSurvey';
import { FC, useCallback, useMemo, useState } from 'react';

import ClientAPI from 'common/ClientAPI';
import { OutboundOutlined } from '@mui/icons-material';
import { Spinner } from 'components/common/Spinner';
import { TelegramIcon } from 'components/icons/TelegramIcon';
import { Uris } from 'Uris';
import classes from './SurveyPage.module.scss';
import { colorPrimary10 } from 'common/params';
import { getCookie } from 'common/utils';
import { useLifecycles } from 'react-use';
import { useMessage } from 'components/message/useMessage';
import { useTracking } from 'common/hooks/useTracking';

enum Category {
  NFT = 'NFT',
  DE_FI = 'DeFi',
  GAME_FI = 'GameFi',
  AGENCY = 'Agency',
  OTHER = 'Other',
}

export interface UsernameCheckResult {
  available: boolean;
  detail?: string;
}

export const SurveyPage: FC = () => {
  const [survey, setSurvey] = useState<UserSurvey>(DefaultUserSurveyData);
  const [checking, setChecking] = useState<boolean>(false);
  const { showMessage } = useMessage();
  const { track } = useTracking();
  const [clicked, setClicked] = useState<boolean>(false);
  const [availableClicked, setAvailableClicked] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [tgUsernameCheckResult, setTgUsernameCheckResult] = useState<UsernameCheckResult>({ available: false });

  useLifecycles(
    () => {
      track('form_start', {
        sub_event: 'survey_viewed',
      });
    },
    () => {},
  );

  const surveyValid = useMemo(() => {
    if (!survey.job) return false;
    if (!survey.company) return false;
    if (!survey.category) return false;
    if (!survey.display_name) return false;
    if (!survey.telegram_username) return false;
    if (!tgUsernameCheckResult.available) return false;
    return true;
  }, [survey, tgUsernameCheckResult.available]);

  const onSubmitBtnClick = useCallback(async () => {
    setClicked(true);
    if (!surveyValid) return;
    setSubmitting(true);
    await ClientAPI.createUserSurvey({ ...survey, telegram_username: survey.telegram_username.replaceAll('@', '') })
      .then(async ({ status }) => {
        if (status === 'success') {
          await track('form_submit', {
            sub_event: 'survey_completed',
            custom_props: {
              job: survey.job,
              company: survey.company,
              category: survey.category,
              telegram_username: survey.telegram_username,
            },
          }).finally(() => {
            // complete user survey -> directly refresh page using window location
            const redirect_url = getCookie('redirect_url');
            if (redirect_url) {
              window.location.replace(redirect_url);
            } else {
              window.location.replace(Uris.Pages.Root);
            }
          });
        } else {
          showMessage('Unexpected error', 'error');
        }
      })
      .catch((error) => {
        showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [survey, surveyValid, showMessage, track]);

  const checkUsernameAvailable = useCallback(async () => {
    setAvailableClicked(true);
    if (!survey.telegram_username) return;
    // currently do not need to double check telegram exist or not
    // directly set check result to true
    setTgUsernameCheckResult({ available: true });
    setChecking(false);

    // below code is for telegram double checking, currently not used
    // setChecking(true);
    // await ClientAPI.checkTelegramUsernameAvailable(survey.telegram_username.replaceAll('@', ''))
    //   .then(({ status, data }) => {
    //     if (status === 'success' && data) {
    //       setTgUsernameCheckResult(data);
    //     } else {
    //       showMessage('Unexpected error', 'error');
    //     }
    //   })
    //   .catch((error) => {
    //     showMessage(error instanceof Error ? error.message : 'Unknow Error', 'error');
    //   })
    //   .finally(() => {
    //     setChecking(false);
    //   });
  }, [survey.telegram_username]);

  return (
    <Stack direction='row' className={classes.root}>
      <Stack className={classes.imgContainer}>
        <img alt='login' src={Uris.Public.Image.User.Survey} className={classes.img} />
      </Stack>
      <Stack className={classes.block}>
        <Stack className={classes.container} spacing={3}>
          <Typography variant='h4' color={colorPrimary10}>
            Hello!
          </Typography>
          <Stack spacing={2}>
            <Typography variant='body1'>
              Tell us a bit more about yourself so we can make growing3 better for you.
            </Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
              <FormControl>
                <InputLabel error={clicked && !survey.display_name}>Name</InputLabel>
                <OutlinedInput
                  size='medium'
                  label='Name'
                  error={clicked && !survey.display_name}
                  value={survey.display_name}
                  onChange={(e) => setSurvey((old) => ({ ...old, display_name: e.target.value }))}
                />
              </FormControl>
              <FormControl>
                <InputLabel error={clicked && !survey.job}>Your job title</InputLabel>
                <OutlinedInput
                  size='medium'
                  label='Your job title'
                  error={clicked && !survey.job}
                  value={survey.job}
                  onChange={(e) => setSurvey((old) => ({ ...old, job: e.target.value }))}
                />
              </FormControl>
            </Stack>
            <FormControl>
              <InputLabel error={clicked && !survey.company}>Project Name</InputLabel>
              <OutlinedInput
                size='medium'
                label='Project Name'
                error={clicked && !survey.company}
                value={survey.company}
                onChange={(e) => setSurvey((old) => ({ ...old, company: e.target.value }))}
              />
            </FormControl>
            <FormControl>
              <InputLabel error={clicked && !survey.category}>Project's Category</InputLabel>
              <Select
                className={classes.select}
                size='medium'
                label="Project's Category"
                error={clicked && !survey.category}
                value={survey.category}
                onChange={(e) => e.target.value && setSurvey((old) => ({ ...old, category: e.target.value }))}
              >
                {Object.values(Category).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel
                error={!!tgUsernameCheckResult.detail || ((clicked || availableClicked) && !survey.telegram_username)}
              >
                Telegram username
              </InputLabel>
              <OutlinedInput
                size='medium'
                label='Telegram username'
                placeholder='@username'
                error={!!tgUsernameCheckResult.detail || ((clicked || availableClicked) && !survey.telegram_username)}
                value={survey.telegram_username}
                onChange={(e) => setSurvey((old) => ({ ...old, telegram_username: e.target.value }))}
              />
              {tgUsernameCheckResult.detail ? (
                <FormHelperText error>{tgUsernameCheckResult.detail}</FormHelperText>
              ) : null}
            </FormControl>
            <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={3}>
              <Stack spacing={1.5}>
                <Stack spacing={0.5}>
                  <Typography>Join our Telegram</Typography>
                  <Typography variant='body2'>
                    Please ensure to join our Telegram channel before submit the form.
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='center' spacing={1} className={classes.telegram}>
                  <TelegramIcon />
                  <Typography variant='body2'>Growing3 TG channel</Typography>
                  <OutboundOutlined
                    fontSize='small'
                    className={classes.link}
                    onClick={() => window.open(Uris.External.Telegram, '_blank')}
                  />
                </Stack>
              </Stack>
              <Stack>
                {checking ? (
                  <Spinner />
                ) : (
                  <Checkbox checked={tgUsernameCheckResult.available} onClick={checkUsernameAvailable} />
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row-reverse' spacing={1}>
            <Button
              variant='contained'
              disabled={submitting}
              startIcon={submitting ? <Spinner size={24} /> : null}
              onClick={onSubmitBtnClick}
            >
              Create an account
            </Button>
          </Stack>
          <Typography variant='body2' className={classes.note}>
            <Link variant='body2' href={Uris.External.TermsOfService} target='_blank'>
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link variant='body2' href={Uris.External.PrivacyPolicy} target='_blank'>
              Privacy Policy
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
